import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

// Function to extract UTM parameters from the URL
const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = [];
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm')) {
      utmParams.push(key);
    }
  });
  return utmParams;
};

// Extract UTM parameters dynamically
const customUTMParams = getUTMParams();

const options = {
  api_host: process.env.REACT_APP_POSTHOG_API_HOST,
  custom_campaign_params: customUTMParams // Use dynamically extracted UTM parameters
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      ...(process.env.REACT_APP_AUTH0_AUDIENCE
        ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
        : null),
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
  >
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_API_KEY} options={options}>
      <App />
    </PostHogProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
